<template>
    <div v-if="!access">
        <NotFound />
    </div>
    <div v-else>
        <div class="pb-2 mb-3 border-bottom">
            <h2>Edit Role</h2>
        </div>
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <router-link to="/">Dashboard</router-link>
                </li>
                <li class="breadcrumb-item active">
                    <router-link :to="{name: 'roles'}">
                        <span>Roles</span>
                    </router-link>
                </li>
                <li class="breadcrumb-item active">Edit</li>
            </ol>
        </nav>
        <br />
        <div class="container-fluid">
            <div v-if="api_error">
                <b-alert
                    :show="alertDismissCountDown"
                    dismissible
                    variant="danger"
                    @dismissed="alertDismissCountDown=0"
                    @dismiss-count-down="alertCountDownChanged"
                >{{api_error}}</b-alert>
            </div>
            <div class="col-md-8">
                <form v-on:keyup.enter="updateRole($event)">
                    <div class="form-group row">
                        <label class="col-md-3">
                            Role
                            <span class="text-danger" style="font-weight:bold;">*</span>
                        </label>
                        <div class="col-md-9">
                            <ValidationProvider
                                name="role"
                                rules="required|min:2"
                                v-slot="{ errors }"
                            >
                                <input
                                    type="text"
                                    class="form-control"
                                    aria-describedby="roleHelpInline"
                                    v-model="role"
                                    readonly
                                />
                                <small
                                    v-if="errors.length == 0"
                                    id="roleHelpInline"
                                    class="text-muted field"
                                >&nbsp;</small>
                                <small
                                    v-else
                                    id="roleHelpInline"
                                    class="text-danger field"
                                >{{ errors[0] }}</small>
                            </ValidationProvider>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-md-3">
                            Permissions
                            <span class="text-danger" style="font-weight:bold;">*</span>
                        </label>
                        <div class="col-md-9">
                            <b-form-group>
                                <ValidationProvider
                                    name="permissions"
                                    rules="required"
                                    v-slot="{ errors }"
                                >
                                    <b-form-checkbox-group
                                        id="checkbox-group-1"
                                        v-model="selectedPermissions"
                                        aria-describedby="permissionHelpInline"
                                        :options="permissions"
                                        name="flavour-1"
                                        stacked
                                    ></b-form-checkbox-group>
                                    <small
                                        v-if="errors.length == 0"
                                        id="permissionHelpInline"
                                        class="text-muted field"
                                    >Select one or more Permissions</small>
                                    <small
                                        v-else
                                        id="permissionHelpInline"
                                        class="text-danger field"
                                    >{{ errors[0] }}</small>
                                </ValidationProvider>
                            </b-form-group>
                        </div>
                    </div>

                    <div class="form-group row" style="float:right;">
                        <div class="offset-xs-3 col-xs-9">
                            <button
                                type="button"
                                class="btn btn-primary text-right ma-10px"
                                v-on:click="updateRole()"
                            >Submit</button>
                        </div>
                        <div class="offset-xs-3 col-xs-9">
                            <button
                                type="button"
                                @click="$router.go(-1)"
                                class="btn btn-secondary ma-10px"
                            >Cancel</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>
<script>
import NotFound from "../../errors/NotFound";
import default_error from "../../../assets/js/global";
export default {
    components: {
        NotFound
    },
    data() {
        return {
            role: "",
            permissions: [],
            selectedPermissions: [],
            api_error: "",
            access: false,
            alertDismissCountDown: 0,
            alertDismissSecs: 10
        };
    },
    created() {
        this.access = this.hasPermission("CAN_UPDATE_ROLE");
        this.getPermissions();
        this.getRole();
    },
    methods: {
        alertCountDownChanged(alertDismissCountDown) {
            this.alertDismissCountDown = alertDismissCountDown;
        },
        showAlert() {
            this.alertDismissCountDown = this.alertDismissSecs;
        },
        updateRole: function() {
            this.startProgressBar();
            const data = {
                name: this.role,
                permissions: this.selectedPermissions
            };
            this.axios
                .post(this.$api.update_role, data, this.getAuthHeaders())
                .then(() => {
                    this.updateProgressBar(true);
                    this.$router.push({ name: "roles" });
                })
                .catch(err => {
                    if (!err.response) {
                        this.api_error = default_error.server_error;
                    } else if (
                        err.response &&
                        err.response.data &&
                        err.response.data.message
                    ) {
                        this.api_error = err.response.data.message;
                    } else {
                        this.api_error = default_error.server_error;
                        console.error(err.response.data);
                    }
                    this.updateProgressBar(false);
                    this.showAlert();
                    window.scrollTo(0, 0);
                });
        },
        getPermissions: function() {
            this.startProgressBar();
            this.axios
                .get(this.$api.get_permissions, this.getAuthHeaders())
                .then(response => {
                    const result = response.data;
                    result.data.forEach(permission => {
                        this.permissions.push({
                            text: permission,
                            value: permission
                        });
                    });
                    this.updateProgressBar(true);
                })
                .catch(err => {
                    if (!err.response) {
                        this.api_error = default_error.server_error;
                    } else if (
                        err.response &&
                        err.response.data &&
                        err.response.data.message
                    ) {
                        this.api_error = err.response.data.message;
                    } else {
                        this.api_error = default_error.server_error;
                        console.error(err.response.data);
                    }
                    this.updateProgressBar(false);
                    this.showAlert();
                });
        },
        getRole: function() {
            this.startProgressBar();
            var query = this.getAuthHeaders();
            query["params"] = { name: this.$route.params.role };
            this.axios
                .get(this.$api.get_roles, query)
                .then(response => {
                    const result = response.data.data;
                    this.role = result.role;
                    this.selectedPermissions = result.permissions;
                    this.updateProgressBar(true);
                })
                .catch(err => {
                    if (!err.response) {
                        this.api_error = default_error.server_error;
                    } else if (
                        err.response &&
                        err.response.data &&
                        err.response.data.message
                    ) {
                        this.api_error = err.response.data.message;
                    } else {
                        this.api_error = default_error.server_error;
                        console.error(err.response.data);
                    }
                    this.updateProgressBar(false);
                    this.showAlert();
                });
        }
    }
};
</script>
<style lang="scss">
@import "../../../assets/css/custom.scss";
</style>
